import React, {Fragment, useContext, useEffect, useState} from 'react';
import {Button, Col, Row, Typography} from 'antd';
import {CommentForm} from '~src/components';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';
import {ISubmitCommentEvent} from '~src/components/molecules/CommentForm';
import CommentList from '~src/components/molecules/CommentList';
import {LocaleContext} from '~src/context';
import styled, {css} from 'styled-components';
import {customColors} from '~src/theme/colors';
import {navigate} from 'gatsby';
import {Routes} from '~src/utils/routes';

interface ICommentsSectionProps {
  mode?: string;
}

const CommentsSection: React.FC<ICommentsSectionProps> = ({mode}) => {
  const [page, setPage] = useState(1);
  const [count] = useState(10);
  const {dispatch, comments} = useStoreon<IState, IEvents>('comments');
  const {
    pages: {
      index: {commentsSection},
    },
  } = useContext(LocaleContext);
  useEffect(() => {
    dispatch('comments/fetch-comments', {page, count, approved: 1, orderBy: 'DESC', strategy: 'overwrite'});
  }, []);

  const handleSubmit = (e: ISubmitCommentEvent) => {
    dispatch('comments/send-comment', e);
  };
  const handleClick = () => {
    if (mode === 'homepage') {
      navigate(Routes.comments);
    } else {
      const updatedValue = page + 1;
      setPage(updatedValue);
      dispatch('comments/fetch-comments', {count, page: updatedValue, approved: 1, orderBy: 'DESC', strategy: 'push'});
    }
  };

  return (
    <Row gutter={[0, 50]}>
      <Col span={24}>
        <TitleWrapperStyled $isAccented={comments.isSent}>
          <Typography.Title level={2}>
            <span
              dangerouslySetInnerHTML={{__html: comments.isSent ? commentsSection.sentTitle : commentsSection.title}}
            />
          </Typography.Title>
        </TitleWrapperStyled>

        {!comments.isSent && (
          <Fragment>
            <Col span={24}>
              <Typography.Paragraph>{commentsSection.subtitle}</Typography.Paragraph>{' '}
              <CommentForm isSending={comments.isSending} onSubmit={handleSubmit} />
            </Col>
          </Fragment>
        )}
      </Col>

      <Col span={24}>
        <Row gutter={[20, 20]} style={{paddingBottom: 16}}>
          <Col span={24}>
            <Typography.Title level={3}>{commentsSection.listTitle}</Typography.Title>
          </Col>
          <Col span={24}>
            <CommentList comments={comments.values} />
          </Col>
          {comments.count >= comments.values.length && (
            <Col span={24}>
              <Button size={'large'} onClick={handleClick}>
                {commentsSection.button}
              </Button>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

const TitleWrapperStyled = styled(Row)<{$isAccented: boolean}>`
  ${props =>
    props.$isAccented &&
    css`
      padding: 10px;
      padding-bottom: 0;
      background-color: ${customColors.green100};
    `}
`;

export default CommentsSection;
