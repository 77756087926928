import React, {useContext} from 'react';
import Providers from '~src/components/Providers';
import {ContentView, PageTemplate, Modals} from '~src/components';
import {LocaleContext} from '~src/context';
import {IPage} from '~src/types';
import Seo from '~src/components/Seo';
import {Col, Row, Space} from 'antd';

import CommentsSection from '~src/components/organisms/CommentsSection';

interface ICalculation1Props extends IPage {}

const CommentsPage: React.FC<ICalculation1Props> = ({location, navigate, path}) => {
  const {
    pages: {comments, index},
  } = useContext(LocaleContext);
  return (
    <Providers>
      <Seo
        title={comments.meta.title}
        description={index.meta.description}
        keywords={index.meta.keywords}
        path={path}
      />
      <PageTemplate
        breadcrumbs={[...comments.breadcrumbs, comments.header1]}
        breadcrumbsSource={comments.breadcrumbsSource}
      >
        <ContentView>
          <Space align="center" size={50} direction="vertical">
            <Row align="middle" justify="center">
              <Col span={24}>
                <CommentsSection />
              </Col>
            </Row>
          </Space>
        </ContentView>
      </PageTemplate>
      <Modals navigate={navigate} path={path} location={location} />
    </Providers>
  );
};

export default CommentsPage;
